import { ConfirmDialogComponent } from './../components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { UsersService } from './users.service';
import { Injectable, OnInit, NgModule } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Users } from '../model/users.model';
import * as _moment from 'moment';
import { ConfigConst } from 'src/app/core/util/config.const';
const moment = _moment;

@Injectable({
  providedIn: 'root',
})
export class PagamentoService {
  constructor(
    private snackbar: MatSnackBar,
    private router: Router,
    private httpClient: HttpClient,
    public usersService: UsersService,
    public dialog: MatDialog
  ) {}

  public users: Users = {
    emaillogin: '',
  };

  token = ConfigConst.SuperLogicaToken;
  access_token = ConfigConst.SuperLogicaAccessToken;

  getClientId(idClient: string): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      app_token: this.token,
      access_token: this.access_token,
      // Secret: 143dc59f-08c7-3539-8238-62daffa91586
    });
    return this.httpClient.get<any>(
      'https://api.superlogica.net/v2/financeiro/clientes?id=' + idClient,
      { headers }
    );
  }

  getClienteEmail(email: string): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      app_token: this.token,
      access_token: this.access_token,
      app_teste: 'teste',
      // Secret: 143dc59f-08c7-3539-8238-62daffa91586
    });
    // console.log('https://api.superlogica.net/v2/financeiro/clientes?apenasColunasPrincipais=1&apenasPessoasJuridicas=0&pagina=1&itensPorPagina=50&pesquisaEmTudo='+email+'&status=2');
    return this.httpClient.get<any>(
      'https://api.superlogica.net/v2/financeiro/clientes?apenasColunasPrincipais=1&apenasPessoasJuridicas=0&pagina=1&itensPorPagina=50&pesquisaEmTudo=' +
        email +
        '&status=2',
      { headers }
    );
  }

  getClienteCNPJ(cnpj: string): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      app_token: this.token,
      access_token: this.access_token,
      app_teste: 'teste',
      // Secret: 143dc59f-08c7-3539-8238-62daffa91586
    });
    return this.httpClient.get<any>(
      'https://api.superlogica.net/v2/financeiro/clientes?pesquisa=' + cnpj,
      { headers }
    );
  }

  getContrato(idClient): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      app_token: this.token,
      access_token: this.access_token,
    });
    return this.httpClient.get<any>(
      'https://api.superlogica.net/v2/financeiro/assinaturas?ID_SACADO_SAC=' +
        idClient +
        '&pagina=1&itensPorPagina=50',
      { headers }
    );
  }

  getContratoTrial(idCliente: any): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      app_token: this.token,
      access_token: this.access_token,
    });

    var data = new Date();
    var inicio = '01/01/' + data.getFullYear();
    var final = '31/12/' + data.getFullYear();
    return this.httpClient.get<any>(
      'https://api.superlogica.net/v2/financeiro/recorrencias/recorrenciasdeplanos?apenasTrial=true&CLIENTES[0]=' +
        idCliente +
        '&dtInicio=' +
        inicio +
        '&dtFim=' +
        final +
        '&pagina=1&itensPorPagina=50',
      { headers }
    );
  }

  getCobrancaCliente(idCliente: any): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      app_token: this.token,
      access_token: this.access_token,
    });
    return this.httpClient.get<any>(
      'https://api.superlogica.net/v2/financeiro/cobranca?doClienteComId=' +
        idCliente +
        '&todasDoClienteComId=' +
        idCliente +
        '&dtInicio&dtFim&semFiltroPorData=1&todasDoClienteComIdentificador&status=todos&forcarstatus&pagina=1&itensPorPagina=50&exibirComposicaoDosBoletos=1',
      { headers }
    );
  }

  getPlano(idPlano: any): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      app_token: this.token,
      access_token: this.access_token,
    });
    return this.httpClient.get<any>(
      'https://api.superlogica.net/v2/financeiro/planos?id=' + idPlano,
      { headers }
    );
  }

  getInadimplencia(idcliente: any): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      app_token: this.token,
      access_token: this.access_token,
    });
    return this.httpClient.get<any>(
      'https://api.superlogica.net/v2/financeiro/inadimplencia?apenasColunasPrincipais=1&status=2&id=' +
        idcliente +
        '&pagina=1&itensPorPagina=50',
      { headers }
    );
  }

  getClientesInadimplentes(idcliente: any): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      app_token: this.token,
      access_token: this.access_token,
    });
    return this.httpClient.get<any>(
      'https://api.superlogica.net/v2/financeiro/clientes/inadimplencia?CLIENTES[0]=' +
        idcliente,
      { headers }
    );
  }

  MigrarPlano(
    idPlano: number,
    idPlanoNovo: number,
    QtdParcelas: number,
    IdentificadorPlano: string
  ) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      app_token: this.token,
      access_token: this.access_token,
    });
    var dadosplano = {
      ID_PLANOCLIENTE_PLC: idPlano, //Identificador(identificadorContrato) ou id do contrato no Superlógica.
      DT_CONTRATO_PLC: moment(new Date()).format('MM/DD/YYYY'), //Será considerado como a data do novo contrato.
      ID_PLANO_PLA: idPlanoNovo, //ID do plano no sistema.
      QUANT_PARCELAS_ADESAO: QtdParcelas, //Quantidade de parcelas de sua adesão.
      ST_IDENTIFICADOR_PLC: IdentificadorPlano, //Identificador do contrato.
      FL_MULTIPLO_COMPO: 0, //Caso queira personalizar a migração, informe 1.
      FL_DIFERENCACANCELAMENTO_PLC: 0, //Para desconsiderar a diferença de cobranças já pagas.
    };
    console.log(dadosplano);
    return this.httpClient.put<any>(
      'https://api.superlogica.net/v2/financeiro/assinaturas/migrar',
      dadosplano,
      { headers }
    );
  }

  ContratarPlano(idCliente: string, idPlano: number, cnpj_gestor: string) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      app_token: this.token,
      access_token: this.access_token,
    });
    let body =
      'PLANOS[0][ID_SACADO_SAC]=' +
      idCliente +
      '&' +
      'PLANOS[0][FL_TRIAL_PLC]&' +
      'PLANOS[0][ID_PLANO_PLA]=' +
      idPlano +
      '&' +
      'PLANOS[0][DT_CONTRATO_PLC]=' +
      moment(new Date()).format('MM/DD/YYYY') +
      '&' +
      'PLANOS[0][QUANT_PARCELAS_ADESAO]=6&' +
      'PLANOS[0][ST_IDENTIFICADOR_PLC]=' +
      cnpj_gestor +
      '&' +
      'PLANOS[0][ST_IDENTIFICADOREXTRA_PLC]=' +
      cnpj_gestor +
      '&' +
      'PLANOS[0][FL_NOTIFICARCLIENTE]=1&' +
      'PLANOS[0][VL_DESCONTORENOVACAO_PLC]' +
      '&' +
      'PLANOS[0][cupom]' +
      '&' +
      'ID_VENDEDOR_FOR' +
      '&' +
      'ST_EMAILINDICACAO_PLC' +
      '&' +
      'ADICIONAIS[0][ID_PRODUTO_PRD]' +
      '&' +
      'ADICIONAIS[0][NM_QNTD_PLP]';

    // console.log(body);
    return this.httpClient.post<any>(
      'https://api.superlogica.net/v2/financeiro/assinaturas',
      body,
      { headers }
    );
  }

  getToken(email: string, id: string) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      app_token: this.token,
      access_token: this.access_token,
    });
    // console.log("ID_SUPER =======>",localStorage.getItem('id_super'));
    return this.httpClient.get<any>(
      'https://api.superlogica.net/v2/financeiro/clientes/token?email=' +
        email +
        '&id=' +
        id,
      { headers }
    );
  }

  getCobrancas(id: string) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      app_token: this.token,
      access_token: this.access_token,
    });

    var data = new Date();
    var inicio = '01/01/' + data.getFullYear();
    var final = '12/31/' + data.getFullYear();

    return this.httpClient.get<any>(
      'https://api.superlogica.net/v2/financeiro/cobranca?apenasColunasPrincipais=0&status=pendentes&dtInicio=' +
        inicio +
        '&dtFim=' +
        final +
        '&filtrarpor=&CLIENTES=' +
        id +
        '&GRUPOS&PRODUTOS&idConta&idForma&apenasPessoasJuridicas=0&exibirComposicaoDosBoletos=1&semFiltroPorData&identificador&comStatusRemessa&pagina=1&itensPorPagina=50&tipo',
      { headers }
    );
  }

  contratos: any[] = [];

  consultarplano() {
    this.getClienteEmail(localStorage.getItem('email_login')).subscribe(
      (res) => {
        // DEFINIR ID SUPER
        localStorage.setItem('id_super', res[0]['id_sacado_sac']);
        //PEGA O ID DO CONTRATO
        this.getContrato(res[0]['id_sacado_sac']).subscribe((result) => {
          // console.log(result);
          for (var j = 0, length = result[0]['data'].length; j < length; j++) {
            // console.log(result[0]['data'][j]);
            var inadimplente = result[0]['data'][j]['inadimplente'];
            var idcontrato = result[0]['data'][j]['id_planocliente_plc'];
            var identificador = result[0]['data'][j][
              'st_identificador_plc'
            ].substring(0, 14);
            var finaltrial = result[0]['data'][j]['dt_trialfim_plc'];
            var efetivar = result[0]['data'][j]['fl_primeiropag_plc'];
            var trial = result[0]['data'][j]['fl_trial_plc'];
            this.contratos[identificador] = [];
            this.contratos[identificador].push(idcontrato);
            this.contratos[identificador].push(identificador);
            this.contratos[identificador].push(finaltrial);
            this.contratos[identificador].push(efetivar);
            this.contratos[identificador].push(trial);
            this.contratos[identificador].push(inadimplente);
          }

          if (
            this.contratos.hasOwnProperty(localStorage.getItem('cnpj_gestor'))
          ) {
            // console.log(this.contratos[localStorage.getItem('cnpj_gestor')]);
            localStorage.setItem(
              'id_contrato',
              this.contratos[localStorage.getItem('cnpj_gestor')][0]
            );
            localStorage.setItem(
              'identificador_contrato',
              this.contratos[localStorage.getItem('cnpj_gestor')][1]
            );
            localStorage.setItem(
              'finaltrial_contrato',
              this.contratos[localStorage.getItem('cnpj_gestor')][2]
            );
            localStorage.setItem(
              'efetivar_contrato',
              this.contratos[localStorage.getItem('cnpj_gestor')][3]
            );
            localStorage.setItem(
              'trial_contrato',
              this.contratos[localStorage.getItem('cnpj_gestor')][4]
            );
            localStorage.setItem(
              'inadimplente',
              this.contratos[localStorage.getItem('cnpj_gestor')][5]
            );
          } else {
            localStorage.setItem(
              'id_contrato',
              result[0]['data'][0]['id_planocliente_plc']
            );
            localStorage.setItem(
              'identificador_contrato',
              result[0]['data'][0]['st_identificador_plc'].substring(0, 14)
            );
            localStorage.setItem(
              'finaltrial_contrato',
              result[0]['data'][0]['dt_trialfim_plc']
            );
            localStorage.setItem(
              'efetivar_contrato',
              result[0]['data'][0]['fl_primeiropag_plc']
            );
            localStorage.setItem(
              'trial_contrato',
              result[0]['data'][0]['fl_trial_plc']
            );
            localStorage.setItem(
              'inadimplente',
              result[0]['data'][0]['inadimplente']
            );
          }

          //calcular vencimento
          var vencimento = new Date(
            localStorage.getItem('finaltrial_contrato')
          );
          var hoje = new Date();
          var comp = hoje.getTime() - vencimento.getTime();
          if (comp > 0) {
            this.getInadimplencia(localStorage.getItem('id_super')).subscribe(
              (pendencias) => {
                // console.log(pendencias);
                if (pendencias.length > 0) {
                  localStorage.setItem('trial_contrato', '1');
                  if (window.location.pathname != '/financeiro') {
                    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                      width: '70vw',
                      maxWidth: '800px',
                      maxHeight: '90vh',
                      data: {
                        title: 'AVISO!',
                        message:
                          'Você possui pendencias de pagamento, Deseja regularizá-las?',
                      },
                    });
                    dialogRef.afterClosed().subscribe((result) => {
                      if (result) {
                        window.location.href = '/financeiro';
                      }
                    });
                  }
                }
              }
            );
          }

          this.users.emaillogin = localStorage.getItem('email_login');

          this.users.status = 'A';

          console.log(this.contratos);
        });
      }
    );
  }

  CadastrarUsuario($user) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      app_token: this.token,
      access_token: this.access_token,
    });

    var dados = {
      ST_NOME_SAC: $user['Nome'], //Obrigatório. String. Nome ou razão social do cliente.
      ST_NOMEREF_SAC: $user['Nome'], //Obrigatório. String. Nome fantasia do cliente
      ST_CEP_SAC69072026: $user['Cep'], //string. CEP do cliente,
      ST_ENDERECO_SAC: $user['Endereco'], //string. Endereço do cliente.
      ST_NUMERO_SAC: $user['Numero'], //string. Número do endereço.
      ST_BAIRRO_SAC: $user['Bairro'], //string. Bairro.
      ST_COMPLEMENTO_SAC: $user['Complemento'], //string. Complemento.
      ST_CIDADE_SAC: $user['Cidade'], //string. Cidade.
      ST_ESTADO_SAC: $user['Estado'], //string. Estado.
      FL_MESMOEND_SAC: 1, //number. (Boolean 0 ou 1). Caso queira informar um endereço de entrega diferente do endereço de cobrança, você pode ativar esse campo de mesmo endereço de cobrança. Ao ativar esse campo os próximos necessitam ser preenchidos. Se não quiser setar o campo, não é necessário informar no código.
      ST_EMAIL_SAC: $user['Email'], //string. E-mail principal. Notificações gerais serão enviadas para este endereço.
      SENHA: $user['Senha'], //string. Necessária para o cliente acessar a área do cliente.
      SENHA_CONFIRMACAO: $user['Senha'], //string. Confirmação para validar a senha.
      ST_DDD_SAC: $user['DDD'], //string. DDD do telefone fixo, específico para o campo de telefone abaixo.
      ST_TELEFONE_SAC: $user['Telefone'], //string. Telefone.
      ST_FAX_SAC: $user['Celular'], //string. Celular.
      DESABILITAR_MENSALIDADE: 0, //number. (Boolean 0 ou 1) Desabilitar geração de cobranças. Esse campo retira a data de vencimento do cliente para que as cobranças não sejam geradas, no sistema as cobranças são geradas apenas para clientes com dia de vencimento configurado.
      ST_CARTAO_SAC: '', // string. Cartão de crédito.
      ST_MESVALIDADE_SAC: '', //number. Mês de vencimento.
      ST_ANOVALIDADE_SAC: 1234, //number. Ano de vencimento.
      ST_SEGURANCACARTAO_SAC: 1234, //number. Código de segurança do cartão.
      FL_PAGAMENTOPREF_SAC: '', //string. Enviar como "3" (fixo) APENAS caso esteja passando as informações do cartão.
      ST_CODIGOCONTABIL_SAC: '', //string. Código no sistema contábil. Código da conta contábil desse cliente para exportação dos dados para contabilidade.
      FL_RETERISSQN_SAC: 0, //number. (Boolean 0 ou 1). Reter ISSQN. Caso marcado informa o desconto do imposto na cobrança assim que ela for gerada.
      TX_OBSERVACAO_SAC: '', // string. Observações do cadastro do cliente.
      FL_SINCRONIZARFORNECEDOR_SAC: 0, // number. (Boolean 0 ou 1) Esse campo informa ao Superlógica para cadastrar os dados desse cliente como um fornecedor no sistema.
      DT_CADASTRO_SAC: '', // string. Data de cadastro do cliente.
    };

    return this.httpClient.post<any>(
      'https://api.superlogica.net/v2/financeiro/clientes',
      dados,
      { headers }
    );
  }

  CadastrarUserPlano(user: any) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      app_token: this.token,
      access_token: this.access_token,
    });

    let body =
      'idplano=' +
      parseInt(user.plano) +
      '&' +
      'ST_NOME_SAC=' +
      user.nome +
      '&' +
      'ST_EMAIL_SAC=' +
      user.email +
      '&' +
      'FL_PAGAMENTOPREF_SAC=' +
      user.pagamento +
      '&' +
      'QUANT_PARCELAS_ADESAO=' +
      parseInt(user.adesao) +
      '&' +
      'trial=' +
      user.trial +
      '&' +
      'senha=' +
      user.senha +
      '&' +
      'senha_confirmacao=' +
      user.senha +
      '&' +
      'identificador=' +
      user.cnpj +
      '&' +
      'identificadorContrato=' +
      user.cnpj +
      '&' +
      'ST_CGC_SAC=' +
      user.cnpj +
      '&' +
      'ST_CELULAR_SAC=' +
      user.celular +
      '&' +
      'ST_PAIS_SAC=' +
      'Brasil' +
      '&' +
      'ST_CEP_SAC=' +
      user.cep +
      '&' +
      'FL_MESMOEND_SAC=' +
      1 +
      '&' +
      'ST_CARTAO_SAC=' +
      user.cartao +
      '&' +
      'ST_MESVALIDADE_SAC=' +
      user.mes +
      '&' +
      'ST_ANOVALIDADE_SAC=' +
      user.ano +
      '&' +
      'ST_SEGURANCACARTAO_SAC=' +
      user.code +
      '&' +
      'ST_CARTAOBANDEIRA_SAC=' +
      user.bandeira +
      '&' +
      'ST_NOMECARTAO_SAC=' +
      user.nomecartao +
      '&' +
      'aceite_contrato=' +
      user.aceita +
      '&' +
      'cupom=' +
      user.cupom;

    // var dados = {
    //   'idplano':parseInt(user.plano), // Obrigatório. Number. ID do plano no Superlógica.
    //   'ST_NOME_SAC':user.nome, // Obrigatório. String. Nome completo do cliente.
    //   'ST_EMAIL_SAC':user.email, // Obrigatório. String. E-mail do cliente (as notificações serão enviadas para este endereço).
    //   'FL_PAGAMENTOPREF_SAC':user.pagamento, // Obrigatório. Number. ID da forma de pagamento (0: boleto bancário, 3: cartão de crédito e 7 débito automático).
    //   'QUANT_PARCELAS_ADESAO':parseInt(user.adesao),// Não é obrigatório. Number. Número da quantidade de parcelas de adesão, se houver.
    //   'trial':user.trial,// Não é obrigatório. String. informe 1 caso o plano contratado seja um trial.
    //   'senha':user.senha,// Obrigatório. String. Senha informada pelo cliente.
    //   'senha_confirmacao':user.senha,// Obrigatório. String. Confirmação da senha.
    //   'identificador':user.cnpj,// Não é obrigatório. String. Identificador do cadastro do cliente.
    //   'identificadorContrato':user.cnpj,// Não é obrigatório. String. Identificador da assinatura do cliente.
    //   'ST_CGC_SAC':user.cnpj, // Caso seja obrigatório nas configurações do plano, o envio deste campo é obrigatório. String. CPF ou CNPJ do cliente.
    //   // 'ST_RG_SAC':'319633524',// Caso seja obrigatório nas configurações do plano, o envio deste campo é obrigatório. String. RG do cliente.
    //   // 'ST_ORGAO_SAC':'SSP',// Caso seja obrigatório nas configurações do plano, o envio deste campo é obrigatório.String. Nome do orgão que expediu o documento do cliente.
    //   // 'ST_INSCRICAO_SAC':'Isento',// Caso seja obrigatório nas configurações do plano, o envio deste campo é obrigatório. String. Inscrição estadual do cliente pessoa jurídica.
    //   // 'ST_INSCMUNICIPAL_SAC':'Isento',// Caso seja obrigatório nas configurações do plano, o envio deste campo é obrigatório. String. Inscrição municipal do cliente pessoa jurídica.
    //   // 'ST_DDD_SAC':'19',// Caso seja obrigatório nas configurações do plano, o envio deste campo é obrigatório. String. DDD do telefone do cliente
    //   // 'ST_TELEFONE_SAC':'33447365',// Caso seja obrigatório nas configurações do plano, o envio deste campo é obrigatório. String. Telefone do cliente
    //   'ST_CELULAR_SAC':user.celular,// Caso seja obrigatório nas configurações do plano, o envio deste campo é obrigatório. String. Número do celular do cliente
    //   // 'ST_FAX_SAC':'',// Caso seja obrigatório nas configurações do plano, o envio deste campo é obrigatório.
    //   'ST_PAIS_SAC':'Brasil',// Caso seja obrigatório nas configurações do plano, o envio deste campo é obrigatório. String. País do cliente
    //   'ST_CEP_SAC':user.cep,// Caso seja obrigatório nas configurações do plano, o envio deste campo é obrigatório. String. CEP do cliente
    //   // 'ST_ENDERECO_SAC':'Rua Serra Geral',// Caso seja obrigatório nas configurações do plano, o envio deste campo é obrigatório. String. Endereço do cliente
    //   // 'ST_NUMERO_SAC':'546',// Caso seja obrigatório nas configurações do plano, o envio deste campo é obrigatório. String. Número da residência do cliente
    //   // 'ST_COMPLEMENTO_SAC':'Perto do posto Ipiranga',// Caso seja obrigatório nas configurações do plano, o envio deste campo é obrigatório. String. Complemento do endereço do cliente
    //   // 'ST_BAIRRO_SAC':'Jardim São Fernando',// Caso seja obrigatório nas configurações do plano, o envio deste campo é obrigatório. String. Bairro do cliente
    //   // 'ST_CIDADE_SAC':'Campinas',// Caso seja obrigatório nas configurações do plano, o envio deste campo é obrigatório. String. Cidade do cliente
    //   // 'ST_ESTADO_SAC':'SP',// Caso seja obrigatório nas configurações do plano, o envio deste campo é obrigatório. String. Estado do cliente
    //   'FL_MESMOEND_SAC':1,// Obrigatório. Number. Informe se "1" se o endereço de entrega é o mesmo do cadastrou ou "0" para cadastrar outro endereço
    //   // 'DT_NASCIMENTO_SAC':'01-01-2019',// Caso seja obrigatório nas configurações do plano, o envio deste campo é obrigatório. String. Data de nascimento do cliente
    //   // 'FL_OPTANTESIMPLES_SAC':1,// Caso seja obrigatório nas configurações do plano, o envio deste campo é obrigatório. Number. Informe "1" se o cliente é do Simples Nacional ou "0" para não é do Simples Nacional.
    //   // 'ISENTO_ICMS':1,// Caso seja obrigatório nas configurações do plano, o envio deste campo é obrigatório. Number. Informe "1" caso o cliente seja isento do ICMS ou "0" para não isento do ICMS
    //   'ST_CARTAO_SAC':user.cartao,// Obrigatório caso o campo FL_PAGAMENTOPREF_SAC seja enviado como "3". String. Número do cartão do cliente.
    //   'ST_MESVALIDADE_SAC':user.mes,// Obrigatório caso o campo FL_PAGAMENTOPREF_SAC seja enviado como "3". String. Mês de validade do cartão do cliente.
    //   'ST_ANOVALIDADE_SAC':user.ano,// Obrigatório caso o campo FL_PAGAMENTOPREF_SAC seja enviado como "3". String. Ano de validade do cartão do cliente.
    //   'ST_SEGURANCACARTAO_SAC':user.code, //Obrigatório caso o campo FL_PAGAMENTOPREF_SAC seja enviado como "3". String. Número de segurança do cartão do cliente.
    //   'ST_CARTAOBANDEIRA_SAC':user.bandeira,// Obrigatório caso o campo FL_PAGAMENTOPREF_SAC seja enviado como "3". String. Bandeira do cartão, deve ser enviado exatamente conforme os exemplos, se não a chamada não será aceita. Exemplos: visa, mastercard, diners, amex, elo - varia de acordo com seu contrato.
    //   'ST_NOMECARTAO_SAC':user.nomecartao,// Obrigatório caso o campo FL_PAGAMENTOPREF_SAC seja enviado como "3". String. Nome que está no cartão do cliente
    //   'aceite_contrato':user.aceita// Obrigatório. Number. Informe 1 para aceitar os termos de contrato.
    // };

    return this.httpClient.post<any>(
      'https://api.superlogica.net/v2/financeiro/checkout',
      body,
      { headers }
    );
  }

  cancelarPlano(idPlano: any) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      app_token: this.token,
      access_token: this.access_token,
    });

    var data = moment(new Date()).format('MM/DD/YYYY');

    let body =
      'ID_PLANOCLIENTE_PLC=' +
      idPlano +
      '&' +
      'DT_CANCELAMENTO_PLC=' +
      data +
      '&' +
      'FL_CANCELAMENTOIMEDIATO';

    console.log(body);

    return this.httpClient.put<any>(
      'https://api.superlogica.net/v2/financeiro/assinaturas',
      body,
      { headers }
    );
  }

  EditarCliente(user) {
    let dados =
      'ID_SACADO_SAC=' +
      Number(user.id) +
      '&' +
      'ST_NOME_SAC=' +
      user.razao +
      '&' +
      'ST_NOMEREF_SAC=' +
      user.name +
      '&' +
      'ST_DIAVENCIMENTO_SAC=' +
      Number(25) +
      '&' +
      'ST_CEP_SAC=' +
      user.cep +
      '&' +
      'ST_ENDERECO_SAC=' +
      user.endereco +
      '&' +
      'ST_NUMERO_SAC=' +
      user.number +
      '&' +
      'ST_COMPLEMENTO_SAC=' +
      user.complemento +
      '&' +
      'ST_CIDADE_SAC=' +
      user.cidade +
      '&' +
      'ST_ESTADO_SAC=' +
      user.uf +
      '&' +
      'FL_MESMOEND_SAC=' +
      Number(1) +
      '&' +
      'ST_DDD_SAC=' +
      user.ddd +
      '&' +
      'ST_TELEFONE_SAC=' +
      user.telefone +
      '&' +
      'ST_FAX_SAC=' +
      user.celular +
      '&' +
      'TX_OBSERVACAO_SAC=' +
      user.nam +
      '&' +
      'ST_INSCMUNICIPAL_SAC=' +
      user.im +
      '&' +
      'ISENTO_ICMS=' +
      Number(user.icms) +
      '&' +
      'FL_OPTANTESIMPLES_SAC=' +
      Number(user.snac);

    if (Number(user.icms) == 1) {
      dados += '&ST_INSCRICAO_SAC=Isento';
    } else {
      dados += '&ST_INSCRICAO_SAC=' + user.ie;
    }

    // console.log(dados);

    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      app_token: this.token,
      access_token: this.access_token,
    });
    // return dados;
    return this.httpClient.put<any>(
      'https://api.superlogica.net/v2/financeiro/clientes',
      dados,
      { headers }
    );
  }

  ConsultarClienteCancelado(cnpj) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      app_token: this.token,
      access_token: this.access_token,
    });

    let body =
      '?apenasColunasPrincipais=0' +
      '&status=1' +
      '&comDiaDeVencimento=0' +
      '&apenasPessoasJuridicas=0' +
      '&pagina=1' +
      '&itensPorPagina=50' +
      '&comDadosDoGrupo=1' +
      '&pesquisa=' +
      cnpj +
      '&semClientesCongelados=1' +
      '&indentificador=123456' +
      '&posicaoEm=02/02/2021';

    // console.log(body);
    return this.httpClient.get<any>(
      'https://api.superlogica.net/v2/financeiro/clientes' + body,
      { headers }
    );
  }
}
