<mat-card fxLayoutGap>
  <p mat-dialog-title>{{ data?.title ? data.title : "Saiba Mais" }}</p>

  <p display-2 *ngIf="!useHtml">
    {{
      data.message
    }}
  </p>

  <div display-2 *ngIf="useHtml" [innerHTML]="data?.message"></div>

  <div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="onConfirm()">
      Voltar
    </button>
  </div>
</mat-card>
