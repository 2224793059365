import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EtapaInfoComponent } from './etapa-info.component';
const routes: Routes = [
  {
    path: 'etapainfo',
    component: EtapaInfoComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EtapaInfoRoutingModule {}
