import { Injectable } from '@angular/core';
import { GridEtapa } from '../model/grid-etapa.model';

@Injectable({
  providedIn: 'root',
})
export class DataEtapaService {
  private dataEtapa: Array<GridEtapa>;
  constructor() {}

  getDataEtapa() {
    return this.dataEtapa;
  }

  setDataEtapa(dataEtapa: Array<GridEtapa>) {
    this.dataEtapa = dataEtapa;
  }
}
