import { Injectable } from '@angular/core';
import { GridChart } from '../model/grid.model';

@Injectable({
  providedIn: 'root',
})
export class DataService{
  private dataChart: Array<GridChart>;

  constructor() {}

  getDataChart() {
    return this.dataChart;
  }

  setDataChart(dataChart: Array<GridChart>) {
    this.dataChart = dataChart;
  }
}
