import { ConfigConst } from './../../core/util/config.const';
import { Contribuinte } from './../model/contribuinte.model';
import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
@Injectable({
  providedIn: 'root',
})
export class ContribuinteService implements OnInit {
  resource = 'contribuintes/';
  uri = ConfigConst.baseURL + this.resource;
  url = ConfigConst.baseURL + 'gestorcontribuinte/';
  cnpj_gestor = localStorage.getItem('cnpj_gestor');

  baseUri = `${ConfigConst.AWSImpostograma}/${ConfigConst.APP}/${this.resource}`;
  authorization = ConfigConst.AWSAuth;
  API = ConfigConst.API;

  token = 'YT1iNjdiZGY2My1iNTFlLTQzODMtODM0NC03OTFjNDE1OTU0OGIsNzE9MTMy';

  constructor(
    private snackbar: MatSnackBar,
    private router: Router,
    private httpClient: HttpClient
  ) {}

  ngOnInit(): void {}

  getContribuintes(cnpjGestor): Observable<Contribuinte[]> {
    return this.httpClient.get<Contribuinte[]>(this.uri + cnpjGestor);
  }

  createGestorContribuinte(
    cnpj_contribuinte: any,
    cnpj_gestor: any
  ): Observable<any> {
    return this.httpClient.post<any>(this.url + 'cadgestorcontribuinte', {
      CNPJ_Contribuinte: cnpj_contribuinte,
      CNPJ_Gestor: cnpj_gestor,
      emaillogin: localStorage.getItem('email_login'),
    });
  }

  deleteContribuinte(CNPJ_Contribuinte: string): Observable<any> {
    localStorage.getItem('email_login');

    return this.httpClient.post<any>(
      `${this.baseUri}deletaContribuintes`,
      {
        CNPJ_Contribuinte,
      },
      {
        headers: {
          Authorization: this.authorization,
          email_login: localStorage.getItem('email_login'),
          cnpj_gestor: localStorage.getItem('cnpj_gestor'),
          db_name: localStorage.getItem('database') ?? '',
        },
      }
    );
  }

  DeleteGestorContribuinte(
    CNPJ_Contribuinte: string,
    Status: string
  ): Observable<any> {
    localStorage.getItem('email_login');

    return this.httpClient.post<any>(
      `${this.API}Contribuinte/DeleteGestorContribuinte/${CNPJ_Contribuinte}/${Status}`,
      {},
      {
        headers: {
          Authorization:
            'Bearer YT1iNjdiZGY2My1iNTFlLTQzODMtODM0NC03OTFjNDE1OTU0OGIsNzE9MTMy',
          email_login: localStorage.getItem('email_login'),
          cnpj_gestor: localStorage.getItem('cnpj_gestor'),
        },
      }
    );
  }

  InsertContribuinte(payload: Contribuinte): Observable<any> {
    localStorage.getItem('email_login');

    return this.httpClient.post<any>(
      `${this.API}Contribuinte/InsertContribuinte`,
      payload,
      {
        headers: {
          Authorization:
            'Bearer YT1iNjdiZGY2My1iNTFlLTQzODMtODM0NC03OTFjNDE1OTU0OGIsNzE9MTMy',
          email_login: localStorage.getItem('email_login'),
          cnpj_gestor: localStorage.getItem('cnpj_gestor'),
        },
      }
    );
  }

  getallContribuintes(): Observable<Contribuinte[]> {
    return this.httpClient.get<Contribuinte[]>(
      this.uri + localStorage.getItem('cnpj_gestor')
    );
  }

  desabilityContribuinte(contribuinte: Contribuinte): Observable<any[]> {
    contribuinte.emaillogin = localStorage.getItem('email_login');
    // console.log(contribuinte);
    return this.httpClient.post<any[]>(this.url + 'disable', contribuinte);
  }

  getGestorContribuinte(gestor: string, modulo: any): Observable<any[]> {
    if (modulo == '') {
      return this.httpClient.get<any[]>(this.url + 'buscar/' + gestor);
    } else {
      var usercampartilhado = 'false';

      if (localStorage.getItem('user_perfil') === '8') {
        usercampartilhado = 'true';
      }

      var headers = new HttpHeaders({
        Authorization:
          'Bearer YT1iNjdiZGY2My1iNTFlLTQzODMtODM0NC03OTFjNDE1OTU0OGIsNzE9MTMy',
        'Content-Type': 'application/json',
        email_login: localStorage.getItem('email_login'),
        cnpj_gestor: localStorage.getItem('cnpj_gestor'),
        usuario_compartilhado: usercampartilhado,
      });

      // console.log("HEADERS ====> ",headers);
      // console.log(this.API+"Modulo/SelectModuloGestorContribuinte/"+modulo);

      // console.log("API==========> ",this.API);
      return this.httpClient.get<any[]>(
        this.API + 'Modulo/SelectModuloGestorContribuinte/' + modulo,
        { headers }
      );
    }
  }

  SelectContribuinteRVIAuditoria(): Observable<any[]> {
    var usercampartilhado = 'false';

    if (localStorage.getItem('user_perfil') === '8') {
      usercampartilhado = 'true';
    }

    var headers = new HttpHeaders({
      Authorization:
        'Bearer YT1iNjdiZGY2My1iNTFlLTQzODMtODM0NC03OTFjNDE1OTU0OGIsNzE9MTMy',
      'Content-Type': 'application/json',
      email_login: localStorage.getItem('email_login'),
      cnpj_gestor: localStorage.getItem('cnpj_gestor'),
      usuario_compartilhado: usercampartilhado,
    });

    // console.log("HEADERS ====> ",headers);
    // console.log(this.API+"Modulo/SelectModuloGestorContribuinte/"+modulo);

    // console.log("API==========> ",this.API);
    return this.httpClient.get<any[]>(
      `${this.API}Contribuinte/SelectContribuinteRVIAuditoria`,
      { headers }
    );
  }

  SelectContribuinteRVIAuditoriaAno(
    CNPJ_Contribuinte: string
  ): Observable<any[]> {
    var headers = new HttpHeaders({
      Authorization:
        'Bearer YT1iNjdiZGY2My1iNTFlLTQzODMtODM0NC03OTFjNDE1OTU0OGIsNzE9MTMy',
      'Content-Type': 'application/json',
      email_login: localStorage.getItem('email_login'),
      cnpj_gestor: localStorage.getItem('cnpj_gestor'),
    });

    // console.log("HEADERS ====> ",headers);
    // console.log(this.API+"Modulo/SelectModuloGestorContribuinte/"+modulo);

    // console.log("API==========> ",this.API);
    return this.httpClient.get<any[]>(
      `${this.API}Contribuinte/SelectContribuinteRVIAuditoriaAno/${CNPJ_Contribuinte}`,
      { headers }
    );
  }

  SelectContribuinteRVIAuditoriaMes(
    CNPJ_Contribuinte: string,
    Ano: string
  ): Observable<any[]> {
    var headers = new HttpHeaders({
      Authorization: `Bearer ${this.token}`,
      'Content-Type': 'application/json',
      email_login: localStorage.getItem('email_login'),
      cnpj_gestor: localStorage.getItem('cnpj_gestor'),
    });

    // console.log("HEADERS ====> ",headers);
    // console.log(this.API+"Modulo/SelectModuloGestorContribuinte/"+modulo);

    // console.log("API==========> ",this.API);
    return this.httpClient.get<any[]>(
      `${this.API}Contribuinte/SelectContribuinteRVIAuditoriaMes/${CNPJ_Contribuinte}/${Ano}`,
      { headers }
    );
  }

  getContribuinteGestor(contribuinte: string): Observable<any[]> {
    return this.httpClient.get<any[]>(
      this.url + 'buscarcontribuinte/' + contribuinte
    );
  }

  usuariocontribuinte(contribuinte: string): Observable<any[]> {
    return this.httpClient.get<any[]>(
      this.url + 'listarusuario/' + contribuinte
    );
  }

  deletarContribuinte(cnpj, gestor): Observable<Contribuinte[]> {
    return this.httpClient.post<Contribuinte[]>(this.uri + 'deletar', {
      CNPJ_Contribuinte: cnpj,
      CNPJ_Gestor: gestor,
    });
  }

  getContribuinteByEmail(email): Observable<any[]> {
    return this.httpClient.post<Contribuinte[]>(
      ConfigConst.baseURL + 'usuarioscompartilhados/buscaemail',
      { EmailLogin: email }
    );
  }

  deletarContribuinteCompartilhado(cnpj, email): Observable<any[]> {
    return this.httpClient.post<Contribuinte[]>(
      ConfigConst.baseURL + 'usuarioscompartilhados/deletar',
      { EmailLogin: email, CNPJ_Contribuinte: cnpj }
    );
  }

  InsertContribuinteEmLote(
    file: string | Blob,
    Aprovar: boolean = false
  ): Observable<any> {
    const formData = new FormData();

    formData.append('01', file);
    formData.append('aprovar', String(Aprovar));

    return this.httpClient.post<any>(
      ConfigConst.API + 'Contribuinte/InsertContribuinteEmLote',
      formData,
      {
        headers: {
          Authorization: ' Bearer ' + this.token,
          email_login: localStorage.getItem('email_login'),
          cnpj_gestor: localStorage.getItem('cnpj_gestor'),
        },
      }
    );
  }

  RegEsp(): Observable<any> {
    return this.httpClient.get<any>(this.uri + 'regesp/1');
  }

  CodigoCidade(uf): Observable<any> {
    return this.httpClient.get<any>(this.uri + 'codigo-cidade/' + uf);
  }
}
