import { GestorUsuario } from 'src/app/shared/model/gestorusuario.model';
import { ConfigConst } from './../../core/util/config.const';
import { Contrato } from './../model/contrato.model';
import { Gestor } from './../model/gestor.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Users } from '../model/users.model';
import { MessageService } from './message.service';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  uriUser = ConfigConst.baseURL + 'usuarios/';
  uriUserUpdate = ConfigConst.baseURL + 'usuarios/update';
  uriGestor = ConfigConst.baseURL + 'gestores/';
  uriContrato = ConfigConst.baseURL + 'contratos/';
  uriGestorUsuario = ConfigConst.baseURL + 'gestorusuario/';
  uriGestorUpdate = ConfigConst.baseURL + 'gestores/update';
  resource = 'store';

  //baseUrl = "http://localhost:8000/api/usuarios/add";

  constructor(
    private snackbar: MatSnackBar,
    private router: Router,
    private http: HttpClient,
    private messageService: MessageService
  ) {}

  createUser(users: Users): Observable<Users[]> {
    return this.http.post<Users[]>(this.uriUser + this.resource, users);
  }

  updateUsuario(users): Observable<Users[]> {
    return this.http.post<Users[]>(this.uriUserUpdate, users);
  }

  getUsuario(email: string): Observable<any[]> {
    return this.http.get<any[]>(this.uriUser + 'buscar/' + email);
  }

  createGestor(gestor: Gestor): Observable<Gestor[]> {
    return this.http.post<Gestor[]>(this.uriGestor + 'store', gestor);
  }

  createGestorUsuario(
    gestorusuario: GestorUsuario
  ): Observable<GestorUsuario[]> {
    return this.http.post<GestorUsuario[]>(
      this.uriGestorUsuario + 'cadastrar',
      gestorusuario
    );
  }

  createContrato(contrato: Contrato): Observable<Contrato[]> {
    return this.http.post<Contrato[]>(this.uriContrato + 'cadastrar', contrato);
  }

  updateGestor(gestor: Gestor): Observable<Gestor[]> {
    return this.http.post<Gestor[]>(this.uriGestorUpdate, gestor);
  }

  showMessage(msg: string, classe: string): void {
    this.messageService.showMessageGenericClass(msg, classe);
  }

  atualizarUsuario(user: Users): Observable<Users[]> {
    return this.http.post<Users[]>(this.uriUser + 'atualizar', user);
  }

  desabilitarUsuario(usuario: any): Observable<any[]> {
    return this.http.post<any[]>(this.uriUser + 'desabilitar', usuario);
  }

  getCardFlag(cardnumber) {
    var cardnumber = cardnumber.replace(/[^0-9]+/g, '');
    var cards = {
      visa: /^4[0-9]{12}(?:[0-9]{3})/,
      mastercard: /^5[1-5][0-9]{14}/,
      diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
      amex: /^3[47][0-9]{13}/,
      discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
      hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
      elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
      jcb: /^(?:2131|1800|35\d{3})\d{11}/,
      aura: /^(5078\d{2})(\d{2})(\d{11})$/,
    };

    for (var flag in cards) {
      if (cards[flag].test(cardnumber)) {
        return flag;
      }
    }

    return false;
  }

  ativarusuario(usuario): Observable<any[]> {
    const formData = new FormData();
    formData.append('email', usuario.emaillogin);
    formData.append('nome', usuario.nome);

    return this.http.post<any[]>(this.uriUser + 'email-ativacao', formData);
  }
}
