import { ContratoService } from './contrato.service';
import { PagamentoService } from './pagamento.service';
import { MatDialog } from '@angular/material/dialog';
import { EventService } from 'src/app/shared/services/event.service';
import { Injectable } from '@angular/core';
import { GestorUsuarioService } from './gestor-usuario.service';
import { MessageService } from './message.service';
import { UserUtil } from 'src/app/core/util/user.util';
import { ModuloService } from './modulo.service';
import { UploadsService } from './uploads.service';
import { GestorService } from './gestor.service';
import { ConfigConst } from 'src/app/core/util/config.const';
import { Router } from '@angular/router';
import $ from 'jquery';
import { GoogleAnalyticsService } from './googleanalytics.service';

@Injectable({
  providedIn: 'root',
})
export class GestorstorageService {
  userUtil = new UserUtil();
  gestores: Array<string> = [];
  constructor(
    private gestorUsuarioService: GestorUsuarioService,
    private messageService: MessageService,
    private eventComponent: EventService,
    public dialog: MatDialog,
    private PagamentoService: PagamentoService,
    private ContratoService: ContratoService,
    private ModuloService: ModuloService,
    private uploadService: UploadsService,
    private GestorService: GestorService,
    private UploadsService: UploadsService,
    private GoogleAnalyticsService: GoogleAnalyticsService,
    private router: Router
  ) {}

  setGestores(email): boolean {
    this.gestorUsuarioService.getGestorByEmail(btoa(email)).subscribe(
      (gestores) => {
        if (gestores.body.length > 0) {
          this.setPermissoes(gestores.body[0].CNPJ_Gestor);
          localStorage.setItem('cnpj_gestor', gestores.body[0].CNPJ_Gestor);
          localStorage.setItem('nome_gestor', gestores.body[0].Nome);
          localStorage.setItem(
            'full_gestor',
            `${gestores.body[0].CNPJ_Gestor} - ${gestores.body[0].Nome}`
          );
          localStorage.setItem('server', gestores.body[0].Servidor);
          localStorage.setItem('path', gestores.body[0].PathUploadArq);
          localStorage.setItem('database', gestores.body[0].NomeDatabase);

          this.eventComponent.setEvent(true);
          // document.location.reload();
        }
      },
      (err) => {
        this.messageService.showMessage(err.error.status, 'warning-snackbar');
        console.error('error: ' + err.error.status);
      }
    );
    return false;
  }

  setGestoresCNPJ(cnpj): boolean {
    this.GestorService.getByIdGestor(cnpj).subscribe(
      (gestores) => {
        if (gestores.body.length > 0) {
          this.setPermissoes(gestores.body[0].CNPJ_Gestor);
          localStorage.setItem('cnpj_gestor', gestores.body[0].CNPJ_Gestor);
          localStorage.setItem('nome_gestor', gestores.body[0].Nome);
          localStorage.setItem(
            'full_gestor',
            `${gestores.body[0].CNPJ_Gestor} - ${gestores.body[0].Nome}`
          );
          localStorage.setItem('server', gestores.body[0].Servidor);
          localStorage.setItem('path', gestores.body[0].PathUploadArq);
          localStorage.setItem('database', gestores.body[0].NomeDatabase);

          this.eventComponent.setEvent(true);
          // document.location.reload();
        }
      },
      (err) => {
        this.messageService.showMessage(
          err.error.body.message.status,
          'warning-snackbar'
        );
        console.error('error: ' + err.error.body.message);
      }
    );
    return false;
  }

  setGestorControl(value) {
    localStorage.setItem('gestor_control', value);
  }

  getGestorControl() {
    return localStorage.getItem('gestor_control') === 'true' ? true : false;
  }

  setPermissoes($cnpj: string) {
    this.uploadService.sizeUpload().subscribe(
      (res) => {
        var tamanho = Number(res[0]['DefaultUploadMB']) * 1048576;
        localStorage.setItem('size_upload', String(tamanho));
        localStorage.setItem(
          'tamanho_upload',
          String(res[0]['DefaultUploadMB'])
        );
      },
      (err) => {
        console.error(err);
        localStorage.setItem('size_upload', '262144000');
      }
    );

    localStorage.setItem('cnpj_gestor', $cnpj);
    localStorage.setItem('modulos', '');
    localStorage.setItem('validarSuperlogica', 'S');

    if (ConfigConst.APP !== 'impostograma') {
      return (document.location.href = '/dashboard');
    }

    this.definePermissoes($cnpj, true);
  }

  definePermissoes($cnpj: string, autorized: boolean) {
    if (
      localStorage.getItem('email_login') ==
        'sustentacao@impostograma.com.br' ||
      localStorage.getItem('email_login') == 'demo@impostograma.com.br' ||
      localStorage.getItem('email_login') == 'tributario@impostograma.com.br'
    ) {
      localStorage.setItem(
        'modulos',
        JSON.stringify(['1', '2', '3', '4', '5'])
      );
      document.location.href = '/dashboard';
    } else {
      if (autorized) {
        this.gestorUsuarioService
          .permissoesGestor($cnpj)
          .subscribe((res) => {
            if (res.length > 0) {
              for (var j = 0, length = res.length; j < length; j++) {
                if (j == 0) {
                  // localStorage.setItem("limite_cnpj",res[j]["LimiteCNPJ"]);
                  localStorage.setItem('limite_prod', res[j]['LimiteProd']);
                  localStorage.setItem('limite_xml', res[j]['LimiteXML']);
                  localStorage.setItem('tipo_pago', res[j]['TipoPago']);
                }
                localStorage.setItem(
                  res[j]['Identificador'],
                  res[j]['ContratoFuncionalidadeIdentificador']
                );
              }

              this.PagamentoService.getClienteEmail(
                localStorage.getItem('email_login')
              ).subscribe((res) => {
                if (res.length > 0) {
                  localStorage.setItem('id_super', res[0]['id_sacado_sac']);
                }
              });

              this.ContratoService.getByIdContrato(
                localStorage.getItem('cnpj_gestor')
              ).subscribe(
                (res) => {
                  localStorage.setItem(
                    'PlanoId',
                    res[res.body.length - 1]['PlanoId']
                  );
                  if (
                    localStorage.getItem('detAUD') == 'S' &&
                    Number(localStorage.getItem('PlanoId')) == 4
                  ) {
                    localStorage.setItem('Cupom', '1');
                  } else {
                    localStorage.setItem('Cupom', '0');
                  }

                  if (Number(localStorage.getItem('PlanoId')) == 4) {
                    localStorage.setItem('Classic', '1');
                  } else {
                    localStorage.setItem('Classic', '0');
                  }
                },
                (err) => {
                  console.error(err);
                }
              );
            }
          })
          .add(() => {
            this.ModuloService.getModulos().subscribe((res) => {
              if (res.hasOwnProperty('data')) {
                if (res['data']) {
                  var modulos = [];
                  for (var i = 0, len = res['data'].length; i < len; i++) {
                    modulos.push(res['data'][i]['ModuloId']);
                  }
                  localStorage.setItem('modulos', JSON.stringify(modulos));
                  //redireciona para a tela inicial após pegar as permissões
                  document.location.href = '/dashboard';
                } else {
                  // window.location.href = "/login";
                  // console.log(res);
                  this.messageService.showMessage(
                    'Não há módulos ativos para esse contrato, por favor entre em contato com o Suporte',
                    'error-snackbar'
                  );
                  localStorage.setItem('modulos', JSON.stringify([]));
                  console.error('Api não retornou os módulos do Gestor');
                }
              }
            });
          });
      } else {
        console.error('Não foi possível verificar o cliente logado');
      }
    }
  }

  CancelarCliente(token) {
    var resp = '';
    var settings = {
      url: ConfigConst.API + 'Gestor/CancelarGestorContrato',
      method: 'POST',
      timeout: 0,
      async: false,
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        email_login: localStorage.getItem('email_login'),
        cnpj_gestor: localStorage.getItem('cnpj_gestor'),
      },
    };

    $.ajax(settings).done(function (response) {
      resp = response;
    });

    return resp;
  }
}
