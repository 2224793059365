import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  //localEvent = new EventEmitter<any>();
  localEvent = new BehaviorSubject<any>(false);
  uploadGridEvent = new BehaviorSubject<any>(false);

  constructor() {}

  setEvent(event: any) {
    this.localEvent.next(event);
  }

  setUploadGridEvent(event: any) {
    this.uploadGridEvent.next(event);
  }

}
