export class UserUtil {
  getToken() {
    return localStorage.getItem('access_token');
  }

  getEmailLogin() {
    return localStorage.getItem('email_login');
  }

  getPerfil() {
    return localStorage.getItem('user_perfil');
  }

  getCnpjGestor() {
    return localStorage.getItem('cnpj_gestor');
  }

  getFullGestor() {
    return localStorage.getItem('full_gestor');
  }

  getEmailGestor() {
    return localStorage.getItem('email_gestor');
  }

  getGestores() {
    return JSON.parse(localStorage.getItem('gestores'));
  }
}
