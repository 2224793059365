import { AbstractControl } from '@angular/forms';
import {
  isValidCEP,
  isValidCNPJ,
  isValidCPF,
  isValidMobilePhone,
  isValidPhone,
} from '@brazilian-utils/brazilian-utils';
import * as moment from 'moment';

export class CustomValidators {
  static cpf(control: AbstractControl) {
    const { value } = control;
    if (value && isValidCPF(value.replace(/[^0-9]/gim, ''))) return null;

    return { cpf: { invalid: true } };
  }

  static currency(value: number) {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  }

  static formatarCNPJ(cnpj: string): string {
    const cnpjNumerico = cnpj.replace(/[^\d]/g, '');
    const cnpjFormatado = cnpjNumerico.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
      '$1.$2.$3/$4-$5'
    );

    return cnpjFormatado;
  }

  static cnpj(control: AbstractControl) {
    const { value } = control;
    if (value && isValidCNPJ(value.replace(/[^0-9]/gim, ''))) return null;

    return { cnpj: { invalid: true } };
  }

  static cep(control: AbstractControl) {
    const { value } = control;
    if (value && isValidCEP(value.replace(/[^0-9]/gim, ''))) return null;

    return { cep: { invalid: true } };
  }

  static mobilePhone(control: AbstractControl) {
    const { value } = control;
    if (value && isValidMobilePhone(value.replace(/[^0-9]/gim, '')))
      return null;

    return { cep: { invalid: true } };
  }

  static removeAcento(value: string) {
    return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  static validarModulo() {
    switch (localStorage.getItem('modulo')) {
      case '1':
        return 'E-Malote';

      case '2':
        return 'Revisão';

      case '3':
        return 'Segregação';

      case '4':
        return 'Consulta';

      case '5':
        return 'Auditoria';

      default:
        break;
    }
  }

  static phone(control: AbstractControl) {
    const { value } = control;
    if (value && isValidPhone(value.replace(/[^0-9]/gim, ''))) return null;

    return { cep: { invalid: true } };
  }

  public static match(field: string) {
    return (controls: AbstractControl) => {
      if (controls.parent) {
        let matching_value = controls.parent.get(field).value;
        let { value } = controls;
        return value === matching_value ? null : { match: true };
      }

      return null;
    };
  }

  public static beforeDate(field: string, format: string = 'DD/MM/yyyy') {
    return (controls: AbstractControl) => {
      if (controls.parent) {
        const maxDateValue = controls.parent.get(field).value;
        const { value } = controls;

        const dateNow = moment(value, format);
        const maxDate = moment(maxDateValue, format);

        if (dateNow.isAfter(maxDate)) return { beforeDate: true };

        return null;
      }

      return null;
    };
  }

  public static afterDate(field: string, format: string = 'DD/MM/yyyy') {
    return (controls: AbstractControl) => {
      if (controls.parent) {
        const minDateValue = controls.parent.get(field).value;
        const { value } = controls;

        const dateNow = moment(value, format);
        const minDate = moment(minDateValue, format);

        if (dateNow.isBefore(minDate)) return { afterDate: true };

        return null;
      }

      return null;
    };
  }

  public static date(format: string = 'DD/MM/yyyy') {
    return (control: AbstractControl) => {
      const { value } = control;

      if (value === null) return null;
      if (!moment(value, format).isValid()) return { invalidDate: true };

      return null;
    };
  }

  public static minDate(minDateField: any, format: string = 'DD/MM/yyyy') {
    return (controls: AbstractControl) => {
      const { value } = controls;
      console.log(value);

      if (value === null) return null;

      const dateNow = moment(value, format);
      const minDate = moment(minDateField.format(format), format);

      if (dateNow.isBefore(minDate)) return { minDate: true };

      return null;
    };
  }

  public static removerCaracteresEspeciais(value: string) {
    return value.replace(
      /[!#"#$%&'()*+,\-/:;<=>?@[\\\]^ˆ{|}~‘’¿À-Ö×Ø-ÿ ]/g,
      ''
    );
  }

  public static primeiraLetraMaiscula(value: string) {
    const palavras = value.split(' ');

    return palavras
      .map((palavra: string) => {
        return palavra[0].toUpperCase() + palavra.substring(1);
      })
      .join(' ');
  }

  public static maxDate(maxDateField: any, format: string = 'DD/MM/yyyy') {
    return (controls: AbstractControl) => {
      const { value } = controls;

      if (value === null) return null;

      const dateNow = moment(value, format);
      const maxDate = moment(maxDateField.format(format), format);

      if (dateNow.isAfter(maxDate)) return { maxDate: true };

      return null;
    };
  }
}
