import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Input, Inject } from '@angular/core';
import { ConfigConst } from 'src/app/core/util/config.const';

export interface DialogData {
  title: string;
  subtitle: string;
  text: string;
  contratar?: boolean;
}

@Component({
  selector: 'app-upgrade-modal',
  templateUrl: './upgrade-modal.component.html',
  styleUrls: ['./upgrade-modal.component.css'],
})
export class UpgradeModalComponent implements OnInit {
  @Input() title: string;
  @Input() text: string;
  @Input() subtitle: string;

  constructor(
    public dialogRef: MatDialogRef<UpgradeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  contratar: boolean;

  ngOnInit(): void {
    this.contratar = !!this.data.contratar;
  }

  upgrade() {
    if (localStorage.getItem('modulo') === ConfigConst.modulo.recuperacao) {
      return true;
    }

    window.open(
      'https://impostograma.com.br/upgrade-de-plano/?email=' +
        localStorage.getItem('email_login'),
      '_blank'
    );
    return true;
  }
  
  contratarPlano() {
    this.dialogRef.close(true);
  }
}
