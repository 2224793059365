import { ConfigConst } from 'src/app/core/util/config.const';
import { GestorUsuarioService } from 'src/app/shared/services/gestor-usuario.service';
import { EventService } from 'src/app/shared/services/event.service';
import { GestorstorageService } from '../../../../../shared/services/gestorstorage.service';
import { Contrato } from '../../../../../shared/model/contrato.model';
import { ContabilidadesComponent } from '../../contabilidades.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GestorUsuario } from '../../../../../shared/model/gestorusuario.model';
import { MessageService } from '../../../../../shared/services/message.service';
import { GestorService } from '../../../../../shared/services/gestor.service';
import {
  Gestor,
  GestorContribuintePOC,
  PayloadCadastraContrato,
} from 'src/app/shared/model/gestor.model';
import { UsersService } from '../../../../../shared/services/users.service';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormGroup,
  FormControl,
} from '@angular/forms';
import { NgxViacepService, Endereco, ErroCep } from '@brunoc/ngx-viacep';
@Component({
  selector: 'app-adicionarcontabilidade',
  templateUrl: './adicionarcontabilidade.component.html',
  styleUrls: ['./adicionarcontabilidade.component.scss'],
})
export class AdicionarcontabilidadeComponent implements OnInit {
  @Output() myEvent = new EventEmitter();

  Contabilidade = ConfigConst.Contabilidade;
  daContabilidade = ConfigConst.daContabilidade;

  ufList: string[] = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO',
  ];

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;

  planoId: string = '1';

  loading: boolean = false;

  selectOptionsTipos = ['PDV', 'NFE'];
  selectYears = [];

  selectMonth = [
    {
      Valor: '01',
      Nome: 'Janeiro',
    },
    {
      Valor: '02',
      Nome: 'Fevereiro',
    },
    {
      Valor: '03',
      Nome: 'Março',
    },
    {
      Valor: '04',
      Nome: 'Abril',
    },
    {
      Valor: '05',
      Nome: 'Maio',
    },
    {
      Valor: '06',
      Nome: 'Junho',
    },
    {
      Valor: '07',
      Nome: 'Julho',
    },
    {
      Valor: '08',
      Nome: 'Agosto',
    },
    {
      Valor: '09',
      Nome: 'Setembro',
    },
    {
      Valor: '10',
      Nome: 'Outubro',
    },
    {
      Valor: '11',
      Nome: 'Novembro',
    },
    {
      Valor: '12',
      Nome: 'Dezembro',
    },
  ];

  gestor: Gestor = {
    CNPJ_Gestor: '',
    Nome: '',
    CEP: '',
    Telefone: '',
    DataHoraContratacaoCorrente: '',
    Status: 'A',
  };

  gestorContribuintePOC: GestorContribuintePOC[] = [
    {
      CNPJ_Contribuinte: '',
      Ano: '',
      Mes: '',
      Tipo: '',
      QuantidadeArquivos: 0,
    },
  ];

  gestorusuario: GestorUsuario = new GestorUsuario();

  isLinear = true;

  hide = true;

  contrato: Contrato = {
    CNPJ_Gestor: '',
    DataHoraContratacao: '',
    QtdeCaixas: 0,
    ValorContrato: '',
  };

  confirmsenha: string = '';

  selectplan: string = '';
  qtdcaixas: number = 0;
  precoplan: string = '';

  constructor(
    public dialog: MatDialog,
    private usersService: UsersService,
    private _formBuilder: FormBuilder,
    private GestorService: GestorService,
    public MessageService: MessageService,
    public dialogRef: MatDialogRef<ContabilidadesComponent>,
    public GestorstorageService: GestorstorageService,
    public EventService: EventService,
    public GestorUsuarioService: GestorUsuarioService,
    private viacep: NgxViacepService
  ) {}

  ngOnInit(): void {
    this.firstFormGroup = this._formBuilder.group({
      CNPJ_Gestor: ['', Validators.required],
      Razao_Social: ['', Validators.required],
      CEP: ['', Validators.required],
      Telefone: ['', Validators.required],
      Endereco: ['', Validators.required],
      Numero: ['', Validators.required],
      Complemento: [''],
      Cidade: ['', Validators.required],
      UF: ['', Validators.required],
      NomeContato: ['', Validators.required],
      EmailContato: ['', Validators.required],
      Senha: ['', Validators.required],
    });

    this.secondFormGroup = this._formBuilder.group({
      CNPJ_Contribuinte0: [Validators.required],
      Ano0: [Validators.required],
      Mes0: [Validators.required],
      Tipo0: [Validators.required],
      QuantidadeArquivos0: [Validators.required],
    });

    this.selectYears.push(
      new Date(new Date().setFullYear(new Date().getFullYear()))
    );

    for (var i = 1, length = 9; i < length; i++) {
      this.selectYears.push(
        new Date(new Date().setFullYear(new Date().getFullYear() - i))
      );
    }
  }

  cadastrarGestorPOC(payload: GestorContribuintePOC): void {
    this.GestorService.criaGestorPOC(payload).subscribe(
      () => {},
      (err) => {
        console.log(err);

        this.MessageService.showMessage(
          'Não foi possível criar as configurações da POC, entre em contato com o suporte!',
          'error-snackbar'
        );
      }
    );
  }

  cadastrarGestor(): void {
    this.gestor.Cidade = String(this.gestor.Cidade)
      .normalize('NFKC')
      .toUpperCase();
    this.gestor.Complemento = String(this.gestor.Complemento)
      .normalize('NFKC')
      .toUpperCase();
    this.gestor.Endereco = String(this.gestor.Endereco)
      .normalize('NFKC')
      .toUpperCase();
    this.gestor.Nome = String(this.gestor.Nome).normalize('NFKC').toUpperCase();

    const payload: PayloadCadastraContrato = {
      idPlano: this.planoId,
      Nome: this.gestor.ContatoNome,
      RazaoSocial: this.gestor.Nome,
      Status: 'A',
      Perfil: '5',
      Senha: this.gestor.Senha,
      Celular: this.gestor.Telefone,
      App: ConfigConst.APPSite,
      CEP: this.gestor.CEP,
      Cidade: this.gestor.Cidade,
      Complemento: this.gestor.Complemento,
      Endereco: this.gestor.Endereco,
      Nro: String(this.gestor.Nro),
      UF: this.gestor.UF,
    };

    this.loading = true;
    this.GestorService.criaContratoGestor(
      payload,
      this.gestor.CNPJ_Gestor,
      this.gestor.ContatoEmail
    )
      .subscribe(
        () => {
          this.dialogRef.close(true);
          this.MessageService.showMessage(
            'Gestor Cadastrado com sucesso!',
            'success-snackbar'
          );
        },
        (err) => {
          console.log(err);

          this.MessageService.showMessage(
            'Não foi possível criar o gestor, entre em contato com o suporte!',
            'error-snackbar'
          );
        }
      )
      .add(() => (this.loading = false));
  }

  cadastrarGestorUsuario(): void {
    // CADASTRAR GESTOR USUARIO
    this.gestorusuario.CNPJ_Gestor = this.gestor.CNPJ_Gestor;
    this.gestorusuario.EmailLogin = localStorage.getItem('email_login');
    console.log(this.gestorusuario);
    this.usersService.createGestorUsuario(this.gestorusuario).subscribe(
      (data) => {
        //console.log("Cadastro GestorUsuario");
        this.CadastrarPlano();
      },
      (err) => {
        //console.log("Erro cadastro GestorUsuario");
        // console.error(err);
        this.dialogRef.close(false);
      }
    );
  }

  CadastrarPlano() {
    if (this.selectplan == '') {
      this.MessageService.showMessage(
        'Por favor selecione um Plano',
        'warning-snackbar'
      );
    } else {
      //console.log("cadastrar plano = "+this.selectplan+" caixas"+this.qtdcaixas+" valor"+this.precoplan);

      this.contrato.CNPJ_Gestor = this.gestor.CNPJ_Gestor;
      this.contrato.DataHoraContratacao =
        this.gestor.DataHoraContratacaoCorrente;
      this.contrato.QtdeCaixas = this.qtdcaixas;
      this.contrato.ValorContrato = this.precoplan;

      this.usersService.createContrato(this.contrato).subscribe(
        (data) => {
          this.MessageService.showMessage(
            'Plano registrado com sucesso',
            'success-snackbar'
          );
          //console.log(data);
          this.EventService.setEvent(true);
          this.dialogRef.close({ confirm: undefined });
          document.location.reload();
        },
        (err) => {
          this.MessageService.showMessage(
            'Ops! algo deu errado',
            'error-snackbar'
          );
          console.log(err);
          this.dialogRef.close(false);
        }
      );
    }
  }

  adicionarContabilidade() {
    this.cadastrarGestor();

    if (this.planoId === '1') {
      this.gestorContribuintePOC.map((payload) => {
        this.cadastrarGestorPOC(payload);
      });
    }
  }

  handleAddNovaConfiguracao() {
    let index = this.gestorContribuintePOC.length;

    Object.keys(this.gestorContribuintePOC[0]).forEach((el) => {
      this.secondFormGroup.addControl(el + String(index), new FormControl());
    });

    this.gestorContribuintePOC.push({
      CNPJ_Contribuinte: '',
      Ano: '',
      Mes: '',
      Tipo: '',
      QuantidadeArquivos: 0,
    });
  }

  buscacep() {
    this.viacep
      .buscarPorCep(this.gestor.CEP)
      .then((data: Endereco) => {
        this.firstFormGroup.controls['Endereco'].setValue(
          data['logradouro'].substr(0, 50).normalize('NFKC').toUpperCase()
        );
        this.firstFormGroup.controls['Numero'].setValue('');

        this.firstFormGroup.controls['Cidade'].setValue(
          data['localidade'].substr(0, 50).normalize('NFKC').toUpperCase()
        );
        this.firstFormGroup.controls['UF'].setValue(data['uf']);

        // console.log(data);
      })
      .catch((error: ErroCep) => {
        // Alguma coisa deu errado :/
        console.log(error.message);
      });
  }
}
