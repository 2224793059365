import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from '@angular/common/http';
import { registerLocaleData } from '@angular/common';

import ptBr from '@angular/common/locales/pt';

// COMPONENTS
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './shared/components/components.module';
import { DashboardModule } from './view/components/dashboard/dashboard.module';
import { FinanceiroComponent } from './view/components/financeiro/financeiro.component';
import { ResizableModule } from 'angular-resizable-element';
import { LoteComponent } from './view/components/lote/lote.component';

// MODULES / MATERIAL
import { ChartsModule } from 'ng2-charts';

import { NgxMaskModule } from 'ngx-mask';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';

// SERVICES
import { AuthService } from './shared/services/auth.service';
import { EmailService } from './shared/services/email.service';

import { GoogleAnalyticsService } from './shared/services/googleanalytics.service';
import { AuthGuard } from './shared/guards/auth-guard';
import { UserRegisterGuard } from './shared/guards/user-register-guard';
import { InterceptorService } from './shared/components/loader/interceptor.service';

import { MatPaginatorIntlCro } from './view/customClass';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';

import { MatFileUploadModule } from 'mat-file-upload';

import { AtualizarcontabilidadeComponent } from './view/components/contabilidades/components/atualizarcontabilidade/atualizarcontabilidade.component';
import { UpgradeModalComponent } from './view/components/upgrade-modal/upgrade-modal.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FundamentacaoLegalComponent } from './view/components/fundamentacao-legal/fundamentacao-legal.component';
import { MessageService } from './shared/services/message.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { UploadauditoriaComponent } from './view/components/uploadauditoria/uploadauditoria.component';
import { NgxViacepModule } from '@brunoc/ngx-viacep';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectFilterModule } from 'mat-select-filter';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { ValidarcsvComponent } from './view/components/validarcsv/validarcsv.component';
import { MatRadioModule } from '@angular/material/radio';
import { TextFieldModule } from '@angular/cdk/text-field';
import { UploadModalComponent } from './view/components/upload-modal/upload-modal.component';
import { ImportarChavesComponent } from './view/components/importar-chaves/importar-chaves.component';
import { MatChipsModule } from '@angular/material/chips';
import { AdicionarIntegracaoComponent } from './view/components/integracoes/components/adicionar-integracao/adicionar-integracao.component';
import { AtualizarIntegracaoComponent } from './view/components/atualizar-integracao/atualizar-integracao.component';

registerLocaleData(ptBr);

@NgModule({
  declarations: [
    AppComponent,
    FinanceiroComponent,
    AtualizarcontabilidadeComponent,
    LoteComponent,
    UpgradeModalComponent,
    FundamentacaoLegalComponent,
    UploadauditoriaComponent,
    ValidarcsvComponent,
    UploadModalComponent,
    ImportarChavesComponent,
    AdicionarIntegracaoComponent,
    AtualizarIntegracaoComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    DragDropModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    DashboardModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    CommonModule,
    ComponentsModule,
    ChartsModule,
    TextFieldModule,
    MatDialogModule,
    MatToolbarModule,
    MatCardModule,
    MatChipsModule,
    MatSortModule,
    MatIconModule,
    MatProgressBarModule,
    MatCheckboxModule,
    MatTableModule,
    MatMenuModule,
    MatPaginatorModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSelectFilterModule,
    MatSidenavModule,
    MatStepperModule,
    ResizableModule,
    MatTabsModule,
    MatButtonModule,
    MatSnackBarModule,
    MatFileUploadModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatRadioModule,
    MatDatepickerModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxMaskModule,
    NgxViacepModule,
    RouterModule.forRoot([
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
        canActivate: [AuthGuard],
      },
      {
        path: 'dashboard',
        // redirectTo: 'dashboard',
        pathMatch: 'full',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./view/components/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
    ]),
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    NgxMaskModule,
    MatExpansionModule,
    MatCheckboxModule,
    DragDropModule,
    MatChipsModule,
    MatInputModule,
  ],
  providers: [
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorIntlCro,
    },
    Title,
    AuthService,
    GoogleAnalyticsService,
    AuthGuard,
    UserRegisterGuard,
    MessageService,
    EmailService,
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    HttpClient,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
