import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  constructor(private snackbar: MatSnackBar, private titleService: Title) {}

  showMessage(
    msg: string,
    classe: 'error-snackbar' | 'success-snackbar' | 'warning-snackbar'
  ): void {
    this.snackbar.open(msg, 'X', {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      duration: 5000,
      panelClass: [classe],
    });
  }

  showMessageGenericClass(msg: string, classe: string): void {
    this.snackbar.open(msg, 'X', {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      duration: 5000,
      panelClass: [classe],
    });
  }

  showMessageNotification(msg: string, classe: string): void {
    if (localStorage.getItem('notificacao') === '1') {
      let audio = new Audio();
      audio.src = '../../../assets/audio/notification.mp3';
      audio.muted = false;
      audio.load();
      audio.play();

      this.titleService.setTitle(msg);

      this.snackbar.open(msg, 'X', {
        horizontalPosition: 'right',
        verticalPosition: 'top',
        duration: 5000,
        panelClass: [classe],
      });
    }
  }

  showTestMessage(msg: string, classe: string): void {
    this.snackbar.open(msg, 'X', {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: [classe],
    });
  }
}
