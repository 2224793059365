import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-financeiro',
  templateUrl: './financeiro.component.html',
  styleUrls: ['./financeiro.component.css']
})
export class FinanceiroComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    var appUrl = encodeURIComponent( document.location.href );
    var iframePlanos = document.createElement("iframe");
    iframePlanos.setAttribute("frameborder","0");
    iframePlanos.setAttribute("width","100%");
    iframePlanos.setAttribute("height","1200");
    iframePlanos.setAttribute("id","financeiro");
    iframePlanos.src=window.location.origin+"/autenticar";
    var el = document.getElementById('financeiro');
    if (el === null) {
      document.getElementById("superlogica_planos_script").appendChild(iframePlanos);
    }

    
    $("#financeiro").bind("load",function(){
      
      $('#financeiro').height( $('#financeiro').contents().outerHeight() );
      
    });
    
    
  }

  


}
