export class Users {
  emaillogin?: string;
  nome?: string;
  status?: string;
  perfil?: string;
  senha?: string;
  celular?: string;
  CNPJ_Gestor?: string;
  EmailAntigo?: string;
  acceptTerm?: string;
  acceptCookies?: string;
  token?: string;
  poc?: boolean;
  modulosCompartilhados?: string;
}
