<h1 mat-dialog-title>{{ data.title }}</h1>
<div>
  <mat-form-field class="full-width" appearance="fill">
    <mat-label>{{ data.subtitle }}</mat-label>
    <mat-select
      [formControl]="options"
      [(ngModel)]="selectedGestor"
      name="gestores"
      (selectionChange)="setSelectedGestor($event)"
    >
      <mat-select-filter
        [noResultsMessage]="'Sem Resultados'"
        [placeholder]="'Filtrar por ...'"
        [displayMember]="'filtro'"
        [array]="listgestores"
        (filteredReturn)="handleFilterGestor($event)"
      ></mat-select-filter>
      <mat-option
        *ngFor="let item of filteredList5"
        [value]="item"
        (onSelectionChange)="change($event)"
      >
        {{ item.CNPJ_GestorFormatado }} - {{ item.Nome }}
      </mat-option>
    </mat-select>

    <!-- <mat-select
      [formControl]="options"
      [(ngModel)]="selectedGestor"
      name="gestores"
      (selectionChange)="setSelectedGestor($event)"
    >
      <mat-select-filter [noResultsMessage]="'Sem Resultados'" [placeholder]="'Filtrar por...'" [displayMember]="'filtro'" (filteredReturn)="filteredList5 =$event"></mat-select-filter>
      <mat-option *ngFor="let item of filteredList5" [value]="item.CNPJ_Gestor">
        {{item.CNPJ_Gestor}} - {{item.Nome}}
      </mat-option>
      <mat-option
        *ngFor="let option of data.gestores"
        [value]="option"
        (onSelectionChange)="change($event)"
        >{{ option.CNPJ_Gestor }} - {{ option.Nome }}
      </mat-option> 
    </mat-select> -->
  </mat-form-field>
  <div>
    <mat-form-field class="full-width">
      <mat-label>{{ Contabilidade }} Atual</mat-label>
      <input matInput disabled value="{{ formatarCNPJ(data.text) }}" />
    </mat-form-field>
  </div>
</div>
<div>
  <button mat-button mat-dialog-close>Fechar</button>
</div>
<mat-progress-bar
  mode="indeterminate"
  *ngIf="progress"
  style="margin: 10px 0 -23px"
></mat-progress-bar>
