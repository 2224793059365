<mat-card class="card">
  <mat-card-header>
    <mat-card-title>Detalhes - {{ tipo }}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="container">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 table">
        <!-- Position Column -->
        <ng-container matColumnDef="contribuinte">
          <th mat-header-cell *matHeaderCellDef>Contribuinte</th>
          <td mat-cell *matCellDef="let element">{{ element.contribuinte }}</td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="competencia">
          <th mat-header-cell *matHeaderCellDef>Competencia</th>
          <td mat-cell *matCellDef="let element">{{ element.competencia }}</td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="tipo">
          <th mat-header-cell *matHeaderCellDef>Tipo</th>
          <td mat-cell *matCellDef="let element">{{ element.tipo }}</td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="dataStatus">
          <th mat-header-cell *matHeaderCellDef>Data Hora Status</th>
          <td mat-cell *matCellDef="let element">{{ element.dataStatus }}</td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="mensagem">
          <th mat-header-cell *matHeaderCellDef>Mensagem</th>
          <td mat-cell *matCellDef="let element">{{ element.mensagem }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <button mat-raised-button color="primary" (click)="toHome()">
      Retornar
    </button>
  </mat-card-actions>
</mat-card>
