<mat-card fxLayoutGap>
  <p mat-dialog-title *ngIf="showbuttons">{{ data.title }}</p>
  <p mat-dialog-title *ngIf="!showbuttons" class="text-center">
    {{ data.title }}s
  </p>

  <p display-2 *ngIf="showbuttons && !useHtml">{{ data.message }}</p>
  <div display-2 *ngIf="useHtml" [innerHTML]="data.message"></div>
  <div *ngIf="!showbuttons && !useHtml">
    <table class="tables" style="width: 100%">
      <tr>
        <td class="text-center">
          <strong>{{ data.title }}</strong>
        </td>
        <td class="text-center"><strong>DESCRIÇÃO</strong></td>
      </tr>
      <tr *ngFor="let group of data.message">
        <td class="text-center">{{ group[data.title] }}</td>
        <td class="text-center">{{ group.Descricao }}</td>
      </tr>
    </table>
  </div>
  <div
    class="obs-container"
    display-2
    *ngIf="observacao.length > 0"
    [innerHTML]="observacao"
  ></div>

  <div class="actions">
    <div mat-dialog-actions *ngIf="showbuttons">
      <button mat-button color="warn" (click)="onDismiss()">Não</button>
      <button mat-raised-button color="primary" (click)="onConfirm()">
        Sim
      </button>
    </div>

    <button
      *ngIf="showCancelButton"
      class="cancelButton"
      mat-raised-button
      color="primary"
      (click)="onCancel()"
    >
      Cancelar
    </button>
  </div>
</mat-card>
